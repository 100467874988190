import { Checkbox, Column, Table } from '@appkit4/react-components';

import React from 'react';

// component import

export function TableBase({
  headerData,
  originalData,
  selected,
  useCheck = true,
  setSelected,
  renderCheckedCell,
  onRowClick,
}: {
  headerData: string[];
  originalData: any[];
  useCheck?: boolean;
  renderCheckedCell: (row: any, field: string) => void;
  setSelected: (id: number[]) => void;
  selected: number[];
  onRowClick?: (event: MouseEvent, index: number, data: any) => void;
}) {
  // 체크박스 전체 선택 또는 해제
  const onCheckTotalChange = (value: boolean, event: React.SyntheticEvent) => {
    if (
      ((event.target as HTMLElement).parentNode as HTMLElement).className.indexOf(
        'ap-checkbox-label'
      ) > -1
    )
      return;

    if (value) {
      setSelected([...originalData.map((item) => item.id)]);
    } else {
      setSelected([]);
    }
  };

  return (
    <Table
      originalData={originalData}
      hasTitle
      condensed={true}
      onRowClick={onRowClick}
      className='min-h-[430px]'
    >
      {headerData.map((item, idx) => {
        return (
          <Column
            field={item}
            renderCell={renderCheckedCell}
            key={idx}
            className='!font-semibold'
          >
            {idx === 0 && useCheck && originalData.length !== 0 ? (
              <Checkbox
                checked={selected.length === originalData.length}
                onChange={(value, event) => onCheckTotalChange(value, event)}
              >
                {item}
              </Checkbox>
            ) : (
              item
            )}
          </Column>
        );
      })}
    </Table>
  );
}
