import { Button, ButtonGroup, Loading } from '@appkit4/react-components';
import { DescriptionType, PageEndPoint } from 'types';
import { FilteredTable, PanelHeader } from 'components/post';
import { checkAdmin, fomattingTableData } from 'utils';
import {
  useErrorActionsStore,
  useList,
  useModalActions,
  useModalVariant,
  useModalVisible,
  usePostDetailActions,
  usePostListFetchs,
  useSelectedPosts,
} from 'store';
import { useLocation, useNavigate } from 'react-router';

import { ConfirmModal } from 'components/display';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

//component import

// props type
interface PostListPropsType {
  pageName: 'Welcome' | '공지사항' | '자료실' | '문의게시판' | 'FAQ';
  descriptionList: DescriptionType;
  page: PageEndPoint;
}

//component
export function PostList({ pageName, descriptionList, page }: PostListPropsType) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isAdmin = checkAdmin();

  //store
  const list = useList();
  const hasSelectedRow = useSelectedPosts();
  const { getPostList, deleteMultiplePost } = usePostListFetchs();
  const { setError } = useErrorActionsStore();
  const { resetDetail } = usePostDetailActions();

  //modal store
  const visible = useModalVisible();
  const variant = useModalVariant();
  const { onOpen, onClose } = useModalActions();

  React.useEffect(() => {
    onClose();

    // filter 선택 값 변경에 따른 list refetch
    const loadData = async () => {
      const originPermission = sessionStorage.getItem('permissions');
      const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
      const permissions = permissionsArr.join(', ');
      const applications = searchParams.get('application') || permissions;

      const res = await getPostList(page, permissions, `${applications}`, 1);

      res.status !== 200 && setError(true, res.status ?? 0);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (!list) {
    return (
      <Loading
        loadingType='linear'
        className='fixed top-[50%] left-[50%]'
      />
    );
  }

  // 게시글 작성 버튼 click event
  const goToCreatePost = () => {
    navigate(`${location.pathname}/create`);
    resetDetail();
  };

  // 게시글 다중 삭제 버튼 click event
  const onDeleteConfirm = async () => {
    const originPermission = sessionStorage.getItem('permissions');
    const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
    const permissions = permissionsArr.join(', ');
    const resStatus = await deleteMultiplePost(page, permissions);
    resStatus === 200 ? onClose() : setError(true, resStatus);
  };

  // 관리계정 권한 여부에 따른 테이블 상단 buttonset render
  const renderBtns = () => {
    return isAdmin ? (
      <ButtonGroup>
        <Button
          role='button'
          onClick={() => onOpen('delete')}
          disabled={hasSelectedRow.length === 0}
        >
          <span className='Appkit4-icon icon-delete-outline' />
          <span className='body-sm'>삭제</span>
        </Button>
        <Button
          role='button'
          onClick={goToCreatePost}
        >
          <span className='Appkit4-icon icon-edit-outline' />
          <span className='body-sm'>게시글 작성</span>
        </Button>
      </ButtonGroup>
    ) : page === 'qna' ? (
      <ButtonGroup>
        <Button
          role='button'
          onClick={goToCreatePost}
        >
          <span className='Appkit4-icon icon-edit-outline' />
          <span className='body-sm'>게시글 작성</span>
        </Button>
      </ButtonGroup>
    ) : (
      <></>
    );
  };

  // table data에 맞춘 response data 포맷팅 (eng => kor)
  const tableData = fomattingTableData(list);

  return (
    <section className='pannel-base bg-white p-7'>
      <PanelHeader
        title={pageName}
        description={descriptionList.user}
      />
      <FilteredTable
        data={tableData}
        buttonGroup={renderBtns}
        canEdit={isAdmin}
        page={page}
      />
      <ConfirmModal
        visible={visible}
        variant={variant}
        onCancel={onClose}
        onConfirm={onDeleteConfirm}
        onClose={onClose}
      />
    </section>
  );
}
