import * as React from 'react';

import { ACCEPT_FILE_SIZE, INITIAL_UPDATE_FILE_LIST } from 'const';
import { Button, TextArea } from '@appkit4/react-components';
import { usePostDetailActions, useUpdateFileList } from 'store';

import { CustomUploader } from 'components/cutomUploader/CustomUploader';
import { commentListItem } from 'types';

// component import

export interface CommentFormProps {
  type?: 'modal' | 'box';
  onClose?: () => void;
  replycontent: string;
  placeholderWithoutContent?: string;
  placeholderWithContent?: string;
  title?: string;
  hasTitle?: boolean;
  addBtnName?: string;
  className?: string;
  onAddClick?: (files: File[] | null) => void;
  onCommentContentChange: (key: keyof commentListItem, commentsContent: any) => void;
  maxLength?: number | undefined;
  showAttachment?: boolean;
  disabled?: boolean;
  accept?: string;
  defaultAttachment?: string[];
  isEdit?: boolean;
}

export const CommentForm = React.forwardRef<HTMLDivElement, CommentFormProps>((props, ref) => {
  const {
    type = 'box',
    replycontent = '',
    className,
    title = 'Add a new comment',
    placeholderWithContent = 'Your comment',
    placeholderWithoutContent = 'Write a comment...',
    addBtnName = 'Add comment',
    onAddClick,
    onCommentContentChange,
    onClose,
    showAttachment = false,
    disabled = false,
    maxLength,
    accept = '*',
    hasTitle = true,
    defaultAttachment,
    isEdit,
    ...otherProps
  } = props;

  const updateFileList = useUpdateFileList();
  const { setUpdateFileList } = usePostDetailActions();

  const handleCommentChange = (value: string) => {
    onCommentContentChange('content', value);
  };

  const handleOnAddClick = () => {
    onAddClick?.(updateFileList.add_files);
    setUpdateFileList(INITIAL_UPDATE_FILE_LIST);
    onCommentContentChange('content', '');
    onCommentContentChange('file_path', []);
  };

  const onChangefile = (fileList: File[]): void => {
    if (isEdit) {
      const newUpdateFileList = {
        add_files: fileList,
        delete_files: updateFileList.delete_files,
      };
      setUpdateFileList(newUpdateFileList);
    } else {
      const newUpdateFileList = {
        ...updateFileList,
        add_files: [...updateFileList.add_files, ...fileList],
      };
      setUpdateFileList(newUpdateFileList);
    }
  };

  const onRemoveDefaultFile = (fileName: string) => {
    const newUpdateFileList = {
      ...updateFileList,
      delete_files: [...updateFileList.delete_files, fileName],
    };
    setUpdateFileList(newUpdateFileList);
  };

  return (
    <div
      ref={ref}
      className={className}
      {...otherProps}
    >
      {hasTitle && (
        <div className='ap-feeds-comments-header'>
          <div className='ap-feeds-comments-header-title'>{title}</div>
          {type === 'modal' && (
            <button
              type='button'
              aria-label='Close'
              className='ap-feeds-comments-footer-wrapper-attach'
              onClick={onClose}
            >
              <span className='Appkit4-icon icon-close-outline'></span>
            </button>
          )}
        </div>
      )}
      <div className='ap-feeds-comments-body'>
        <TextArea
          maxLength={maxLength}
          title={replycontent.length > 0 ? placeholderWithContent : placeholderWithoutContent}
          value={replycontent}
          onChange={handleCommentChange}
          disabled={disabled}
        ></TextArea>
      </div>
      <div className='ap-feeds-comments-footer-wrapper relative'>
        <div className='w-full'>
          <CustomUploader
            defaultFileName={defaultAttachment}
            multiple={true}
            autoUpload={false}
            acceptFileType={accept}
            maxFileSize={ACCEPT_FILE_SIZE}
            action='/'
            config={{
              trigger: true,
              type: 'inline',
              size: true,
            }}
            className='col-span-3'
            onChange={onChangefile}
            onRemoveDefaultFile={onRemoveDefaultFile}
            isCompact={true}
            buttonClassName='mb-3'
          />
        </div>
        <div className='ap-feeds-comments-footer-buttons absolute right-1'>
          <Button
            kind='primary'
            disabled={!replycontent && showAttachment}
            onClick={handleOnAddClick}
          >
            {addBtnName}
          </Button>
        </div>
      </div>
    </div>
  );
});
