import { APP_LIST, AUTH_URL } from 'const';
import { PageEndPoint, PostDetailType, UpdateFileListType, commentListItem } from 'types';

import { AxiosError } from 'axios';

export const getErrorStatus = (e: unknown) => {
  const error = (e as AxiosError).status;
  return error ?? 0;
};

export const getApplicaions = () => {
  const permissions = sessionStorage.getItem('permissions');
  const permissionArr = permissions ? JSON.parse(permissions) : [];
  return permissionArr;
};

export const getPermissions = () => {
  const grade = sessionStorage.getItem('grade')?.trim().replace(`"`, '');
  const accounts = grade?.includes('Manager') ? ['User'] : ['PwC Manager', 'User'];

  return accounts;
};

export const checkAdmin = () => {
  const grade = sessionStorage.getItem('grade')?.trim().replace(`"`, '');
  return grade !== 'User';
};

export const getFilterList = (arr: string[]) => {
  const newArr = APP_LIST.map((item) => {
    return arr.includes(item)
      ? { label: item, value: item }
      : { label: item, value: item, disabled: true };
  });

  return newArr;
};

export const getDeletePostBody = (type: PageEndPoint, list: number[]) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const deletePostBody = {
    permissions: permissions,
    [`${type}_ids`]: JSON.stringify(list),
  };

  return deletePostBody;
};

export const getCreatePostBody = (item: PostDetailType) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const createPostBody = {
    permissions: permissions,
    title: item.title,
    content: item.content,
    file: item.file_path,
    application: item.application,
  };

  return createPostBody;
};

export const getCreatePostQnABody = (item: PostDetailType) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const createPostBody = {
    category: '문의',
    permissions: permissions,
    title: item.title,
    content: item.content,
    file: item.file_path,
    application: item.application,
  };

  return createPostBody;
};

export const getUpdatePostBody = (item: PostDetailType, files: UpdateFileListType) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const updatePostBody = {
    permissions: permissions,
    title: item.title,
    content: item.content,
    application: item.application,
    add_files: files.add_files ? files.add_files : [],
    delete_files: files.delete_files ? files.delete_files : [],
  };

  return updatePostBody;
};

export const getUpdatePostQnABody = (item: PostDetailType, files: UpdateFileListType) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const updatePostBody = {
    category: '문의',
    permissions: permissions,
    title: item.title,
    content: item.content,
    application: item.application,
    add_files: files.add_files,
    delete_files: files.delete_files,
  };

  return updatePostBody;
};

export const getCreateCommentBody = (
  item: commentListItem,
  postId: string,
  parent_id: number | null,
  files: File[] | null
) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const createCommentBody = {
    permissions: permissions,
    qna_id: postId,
    content: item.content,
    file: files,
    parent_id: parent_id ? parent_id : 0,
  };

  return createCommentBody;
};

export const getUpdateCommentBody = (item: commentListItem, files: UpdateFileListType) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const createCommentBody = {
    permissions: permissions,
    comment_id: item.id,
    content: item.content,
    add_files: files.add_files,
    delete_files: files.delete_files,
  };

  return createCommentBody;
};

export const getDeleteCommentBody = (commentId: number) => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const deleteCommentBody = {
    permissions: permissions,
    comment_id: commentId,
  };

  return deleteCommentBody;
};

export const getDeleteSinglePostBody = () => {
  const originPermission = sessionStorage.getItem('permissions');
  const permissionsArr = originPermission ? JSON.parse(originPermission) : [];
  const permissions = permissionsArr.join(', ');
  const deleteSinglePostBody = {
    permissions: permissions,
  };

  return deleteSinglePostBody;
};

export const pwcLogin = (path: string) => {
  const stateData = {
    redirect_url: path,
    mode: '',
  };
  const encodedState = encodeURIComponent(JSON.stringify(stateData));
  sessionStorage.setItem('redirect_url', path);
  window.location.href = `${AUTH_URL}&state=${encodedState}`;
};
