import { ACCEPT_FILE_SIZE, ACCEPT_FILE_TYPES } from 'const';
import { Input, Select } from '@appkit4/react-components';
import { NoticeResponseKeyType, PostDetailType } from 'types';
import { getApplicaions, getFilterList } from 'utils';
import { usePostDetailActions, useUpdateFileList } from 'store';

import { CustomUploader } from 'components/cutomUploader/CustomUploader';
import React from 'react';
import StyledTextEditor from 'components/display/StyledTextEditor';

//component import

export interface PostEditorProps {
  data: PostDetailType;
  isEdit: boolean;
  onChange: (type: NoticeResponseKeyType, value: any) => void;
}

export interface CustomFile {
  lastModifiedDate: string;
  name: string;
  originFile: File;
  percent: number;
  response: string;
  size: number;
  status: string;
  type: string;
  uid: string;
}

export const PostEditor: React.FC<PostEditorProps> = ({
  data,
  onChange,
  isEdit,
}: PostEditorProps) => {
  const applicaions = getApplicaions();
  const filterList = getFilterList(applicaions);

  const updateFileList = useUpdateFileList();
  const { setUpdateFileList } = usePostDetailActions();

  // file upload and save at store
  const onChangefile = (fileList: File[]): void => {
    if (isEdit) {
      const newUpdateFileList = {
        add_files: fileList,
        delete_files: updateFileList.delete_files,
      };
      setUpdateFileList(newUpdateFileList);
    } else {
      onChange('file_path', fileList);
    }
  };

  const onRemoveDefaultFile = (fileName: string) => {
    const newUpdateFileList = {
      ...updateFileList,
      delete_files: [...updateFileList.delete_files, fileName],
    };

    setUpdateFileList(newUpdateFileList);
  };

  return (
    <form className='mt-7 w-full grid grid-cols-3 gap-4'>
      <Input
        type={'text'}
        title={'제목을 입력해주세요'}
        hideTitleOnInput={data.title === '' ? false : true}
        allowClear={true}
        required={true}
        value={data.title}
        onChange={(value: any) => {
          onChange('title', value);
        }}
        className='col-span-2 '
      ></Input>
      <Select
        hideTitleOnInput={true}
        required={true}
        data={filterList}
        placeholder={'Categories'}
        value={data.application}
        onSelect={(e) => {
          onChange('application', e.toString());
        }}
      ></Select>
      <StyledTextEditor
        data={data.content}
        onChange={(data) => onChange('content', data)}
      />
      <CustomUploader
        defaultFileName={data.file_path}
        multiple={true}
        autoUpload={false}
        acceptFileType={ACCEPT_FILE_TYPES}
        maxFileSize={ACCEPT_FILE_SIZE}
        action='/'
        config={{
          trigger: true,
          type: 'inline',
          size: true,
        }}
        className='col-span-3'
        onChange={onChangefile}
        onRemoveDefaultFile={onRemoveDefaultFile}
      />
    </form>
  );
};
