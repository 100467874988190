import { INITIAL_UPDATE_FILE_LIST, KEY_VALUES } from 'const';
import React, { useEffect, useRef, useState } from 'react';
import { usePostDetailActions, useUpdateFileList } from 'store';

import { UploadList } from 'components/comment';
import { UploadProps } from '@appkit4/react-components';
import classNames from 'classnames';

export interface CustomUploaderProps extends UploadProps {
  defaultFileName?: string[];
  onRemoveDefaultFile?: (fileName: string) => void;
  isCompact?: boolean;
  buttonClassName?: string;
}

// default file list 받아서 삭제/등록하는 uploader
export const CustomUploader: React.FC<CustomUploaderProps> = ({ isCompact = false, ...props }) => {
  const updateFileList = useUpdateFileList();
  const { setUpdateFileList } = usePostDetailActions();

  const [defaultFiles, setDefaultFiles] = useState<string[]>(
    props.defaultFileName ? props.defaultFileName : []
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setUpdateFileList(INITIAL_UPDATE_FILE_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //file drop event
  const onFileDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault && e.preventDefault();
    let fileList = [];
    if (e.type === 'drop') {
      fileList = Array.prototype.slice.call(e.dataTransfer.files);
      setUpdateFileList({ ...updateFileList, add_files: fileList });
      props.onChange && props.onChange(fileList);
    }
  };

  //file change event
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.prototype.slice.call(e.target.files);
    const newFiles = [...updateFileList.add_files, ...fileList];
    setUpdateFileList({ ...updateFileList, add_files: newFiles });
    props.onChange && props.onChange(newFiles);
  };

  //file click event
  const handleClick = () => {
    fileInputRef && fileInputRef.current && fileInputRef.current.click();
  };

  //uploadfile remove event
  const onRemoveFile = (fileName: string) => {
    const newFiles = updateFileList.add_files.filter((file) => file.name !== fileName);
    setUpdateFileList({ ...updateFileList, add_files: newFiles });
    props.onChange && props.onChange(newFiles);
  };

  //default file remove event
  const onRemoveDefaultFile = (fileName: string) => {
    const newFiles = defaultFiles.filter((file) => file !== fileName);
    setDefaultFiles(newFiles);
    props.onRemoveDefaultFile && props.onRemoveDefaultFile(fileName);
  };

  //uploadfile list render
  const uploadList = (value: string[] | File[], removeEvent: (value: string) => void) => (
    <div className='ap-fileupload'>
      <div className='ap-fileupload-wrapper '>
        <UploadList
          files={value}
          onRemove={removeEvent}
        />
      </div>
    </div>
  );

  //uploader component
  const uploader = isCompact ? (
    <button
      className={classNames('ap-feeds-comments-footer-wrapper-attach', props.buttonClassName)}
      type='button'
      onKeyDown={(e) => {
        const { key } = e;
        if ((key === KEY_VALUES.ENTER || key === KEY_VALUES.SPACE) && !props.disabled) {
          handleClick();
        }
      }}
      onClick={handleClick}
    >
      <span
        aria-hidden='true'
        className='Appkit4-icon icon-paperclip-outline'
      ></span>
      <input
        className='ap-feeds-comments-footer-attach-input'
        accept={props.acceptFileType}
        ref={fileInputRef}
        onChange={onFileChange}
        multiple={props.multiple}
        onClick={handleClick}
        tabIndex={-1}
        aria-hidden
        type='file'
      />
    </button>
  ) : (
    <div className='ap-fileupload-content-wrapper content-bottom content-inline'>
      <div className='ap-fileupload-content'>
        <button
          className={classNames('ap-fileupload-drop-btn', props.buttonClassName)}
          type='button'
          onKeyDown={(e) => {
            const { key } = e;
            if ((key === KEY_VALUES.ENTER || key === KEY_VALUES.SPACE) && !props.disabled) {
              handleClick();
            }
          }}
          onClick={handleClick}
        >
          <span className='ap-fileupload-drop-span'>
            {props.description ? (
              props.description()
            ) : (
              <>
                <span>Drag And drop or </span>
                <span
                  className='ap-fileupload-drop-browse-span'
                  data-mode='files'
                >
                  choose File
                </span>
              </>
            )}
          </span>
          <input
            type='file'
            accept={props.acceptFileType}
            className='upload-input'
            ref={fileInputRef}
            onChange={onFileChange}
            multiple={props.multiple}
            onClick={handleClick}
            tabIndex={-1}
            aria-hidden
          />
        </button>
      </div>
    </div>
  );

  return (
    <div
      style={props.style}
      className={classNames('ap-fileupload ', props.className)}
    >
      <div className='ap-fileupload-wrapper'>
        <div>
          <span>{props.uploadTitle}</span>
        </div>
        <div>{props.uploadInstruction}</div>
        <div
          className='a-upload-drag-zone'
          onDrop={(e) => onFileDrop(e)}
          onDragOver={(e) => onFileDrop(e)}
          onDragLeave={(e) => onFileDrop(e)}
        >
          {uploader}
        </div>
        {props.defaultFileName && uploadList(defaultFiles, onRemoveDefaultFile)}
        {updateFileList.add_files.length > 0 && uploadList(updateFileList.add_files, onRemoveFile)}
      </div>
    </div>
  );
};
