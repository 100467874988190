import { ConfirmModal, FileDownloadButton } from 'components/display';
import {
  useCommentActions,
  useModalActions,
  useModalVariant,
  useModalVisible,
  useSelectedComment,
} from 'store';

import { ACCEPT_FILE_TYPES } from 'const';
import { Avatar } from '@appkit4/react-components';
import { CommentForm } from './CommentForm';
import { CommentItemMenu } from './CommentItemMenu';
import React from 'react';
import { commentListItem } from 'types';

//Props Type
interface EditableCommentItemProps {
  type?: 'comment' | 'reply';
  item: commentListItem;
  idx: number;
  openReply?: (list: commentListItem, listIndex: string) => void;
  updateCommentHandler: () => void;
  onDeleteComment: () => void;
  canEdit?: boolean;
  canDelete?: boolean;
}

//component
export function EditableCommentItem({
  type = 'comment',
  item,
  idx,
  openReply,
  updateCommentHandler,
  onDeleteComment,
  canEdit = item.is_editable,
  canDelete = item.is_deletable,
}: EditableCommentItemProps) {
  const [isEdit, setIsEdit] = React.useState(false);

  //store
  const comment = useSelectedComment();
  const { updateSelectedComment, setSelectedComment } = useCommentActions();

  //modal store
  const { onOpen, onClose } = useModalActions();
  const visible = useModalVisible();
  const variant = useModalVariant();

  // change comment 버튼 click event (update comment)
  const onAddClick = () => {
    updateCommentHandler && updateCommentHandler();
    setIsEdit(false);
  };

  const onSelectMenuItem = (value: string) => {
    //선택된 meue component가 속한 comment의 정보 저장
    setSelectedComment(item.id);

    if (value === '삭제') {
      onOpen('deleteComment');
    }
    if (value === '수정') {
      setIsEdit(true);
    }
  };

  // 수정모드의 close 버튼 click event
  const onEditorClose = () => {
    setIsEdit(false);
  };

  return isEdit ? (
    // comment editor
    <div className='comments '>
      <div className='comments-short-name'>
        {item.shortName && (
          <Avatar
            label={item.shortName[0]}
            bgColor={item.shortNameBgColor}
          ></Avatar>
        )}
      </div>
      <div className='comments-detail relative w-full pr-3'>
        <div className='comments-info'>
          {item.fullName && <div className='comments-name'>{item.fullName}</div>}
          {item.commentsTime && <div className='comments-time'>{item.commentsTime}</div>}
        </div>
        <div className='comments-content relative'>
          <CommentForm
            replycontent={comment.content}
            onAddClick={onAddClick}
            showAttachment={true}
            maxLength={420}
            onCommentContentChange={updateSelectedComment}
            hasTitle={false}
            placeholderWithoutContent='Change a comment...'
            placeholderWithContent='Your comment'
            addBtnName='Change comment'
            className='mb-5'
            accept={ACCEPT_FILE_TYPES}
            defaultAttachment={item.file_path}
            isEdit={true}
          />
        </div>
        <button
          type='button'
          aria-label='Close'
          className='ap-feeds-comments-footer-wrapper-attach absolute top-0 right-3'
          onClick={onEditorClose}
        >
          <span className='Appkit4-icon icon-close-outline'></span>
        </button>
      </div>
    </div>
  ) : (
    // comment viewer
    <div className='relative mb-3'>
      <div className='comments'>
        <div className='comments-short-name '>
          {item.shortName && (
            <Avatar
              label={item.shortName[0]}
              bgColor={item.shortNameBgColor}
            ></Avatar>
          )}
        </div>
        <div className='comments-detail relative w-full pr-3'>
          <div className='comments-info'>
            {item.fullName && <div className='comments-name'>{item.fullName}</div>}
            {item.commentsTime && <div className='comments-time'>{item.commentsTime}</div>}
          </div>
          <div className='comments-content relative'>
            <p>{item.content}</p>
            <div className='w-[50rem] grid-row-none mt-3'>
              <FileDownloadButton
                data={item.file_path ? item.file_path : []}
                className='text-sm'
                page='qna'
              />
            </div>
            {type === 'comment' && (
              <button
                className='p-2 rounded hover:bg-container-selected-hover/50'
                onClick={() => openReply && openReply(item, idx.toString())}
              >
                <span className='Appkit4-icon icon-comment-outline mr-2' />
                {item.replyList.length} comments
              </button>
            )}
          </div>
        </div>
        <CommentItemMenu 
          onSelect={(value) => onSelectMenuItem(value)} 
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </div>
      <ConfirmModal
        visible={visible}
        variant={variant}
        onCancel={onClose}
        onConfirm={() => {
          onDeleteComment();
          onClose();
        }}
        onClose={onClose}
      />
    </div>
  );
}
