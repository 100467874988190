import * as React from 'react';

import { axiosClient, getErrorStatus } from 'utils';

import { PostDetailPropsType } from 'components/post';
import { useErrorActionsStore } from 'store';
import { useParams } from 'react-router';

interface FileDownloadButtonPropsType extends PostDetailPropsType {
  data: string[];
  className?: string;
}

export const FileDownloadButton: React.FC<FileDownloadButtonPropsType> = (props) => {
  const { data, className, page } = props;
  const { id } = useParams();
  const fileList = Array.isArray(data) ? data : [data]; // 배열이 아닐 경우 배열로 변환
  const { setError } = useErrorActionsStore();

  const downloadFile = async (fileName: string) => {
    try {
      // window.open(`${BASE_URL}/${page}/${id}/download/${fileName}`, '_blank');
      const url = `${page}/${id}/download/${fileName}`;
      const apiClient = await axiosClient('blob', 'blob');
      const res = await apiClient.get(url);

      if (res.status === 200) {
        const blob = new Blob([res.data]);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        // cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (e) {
      const status = getErrorStatus(e);
      setError(true, status);
      return status;
    }
  };

  return (
    <>
      {fileList.length === 0 || (fileList.length === 1 && fileList[0] === '') ? (
        <p className='text-gray-500'>첨부파일이 없습니다.</p>
      ) : (
        fileList.map((item, idx) => {
          const fileName = item !== null ? item.split('/')[item.split('/').length - 1] : '';
          return (
            <button
              key={`${item}-${idx}`}
              className={`block underline underline-offset-4 text-primary-200 hover:text-primary-400 transition-all ${className}`}
              onClick={() => {
                downloadFile(fileName);
              }}
            >
              <span className='Appkit4-icon icon-paperclip-outline text-primary-400' />
              {fileName}
            </button>
          );
        })
      )}
    </>
  );
};
