import * as React from 'react';

import ClassNames from 'classnames';
import { getFormatFileSize } from 'utils';

interface dataProps {
  showFullFileName?: boolean;
  fileListItemClassName?: string;
  files: any;
  multiple?: any;
  autoUpload?: boolean;
  config?: Object;
  onRemove?: (fileName: string) => void;
}

export const UploadList: React.FC<dataProps> = (props: dataProps) => {
  const { config = {} }: any = props;

  //file list item render
  const renderSuccessContext = (filename: string, filesize: number, i: number) => {
    const loadClass = ClassNames('ap-fileupload-loaded', {
      'modal-loaded': config.type === 'modal',
    });
    const fileNameClass = ClassNames('ap-fileupload-fileName', {
      'color-dark': config.type === 'modal',
    });
    const fileSizeClass = ClassNames('ap-fileupload-fileSize', {
      'color-dark': config.type === 'modal',
    });

    return (
      <div
        className='ap-fileupload-fileList'
        key={i}
      >
        <div className={loadClass}>
          <span className='Appkit4-icon icon-document-text-outline'></span>
          <span className='ap-fileupload-fileSpan'>
            <span className={fileNameClass}>
              <span
                className='fileName'
                title={filename}
              >
                {filename.split('/')[filename.split('/').length - 1]}
              </span>
            </span>
            <span className={fileSizeClass}>
              {config.size && `(${getFormatFileSize(filesize)})`}
            </span>
          </span>
          <button
            type='button'
            className='Appkit4-icon ap-fileupload-loaded-close'
            onClick={() => props.onRemove && props.onRemove(filename)}
          >
            <span className='Appkit4-icon icon-close-outline'></span>
          </button>
        </div>
      </div>
    );
  };

  const { files } = props;
  const classes = ClassNames('ap-fileupload-fileLists !mb-0', {
    multiple: props.multiple,
  });

  //file list render
  const list = files.map((file: File | string, i: number) => {
    return typeof file === 'string'
      ? renderSuccessContext(file, 0, i)
      : renderSuccessContext(file.name, file.size, i);
  });
  return <div className={classes}>{list}</div>;
};
